import request from "../utils/request";
import qs from 'qs'

/* 流量费设置新增 */
export const flowFeeSave = (params) => {
    return request.post('/back/t/flow/fee/save', params)
}

/* 流量费设置分页查询 */
export const getFlowList = (params) => {
    return request.get('/back/t/flow/fee/page/list', params)
}

/* 设置启用禁用 */
export const setFlowFee = (params) => {
    return request.post(`/back/t/flow/fee/setFlowFee?${qs.stringify(params, { indices: false })}`)
}

/* 流量费设置修改 */
export const flowFeeUpdate = (params) => {
    return request.post('/back/t/flow/fee/update', params)
}

/* 流量费详情 */
export const getFlowFeeById = (params) => {
    return request.get('/back/t/flow/fee/getFlowFeeByOrgId', params)
}

/* 流量费删除 */
export const flowFeeDel = (id) => {
    return request.post(`/back/t/flow/fee/deleteById/${id}`)
}