<template>
  <div class="flow-fee-page">
    <div class="btn-area">
      <el-button
        v-show="authorities.includes('BACK:FLOW_FEE_SAVE')"
        @click="handleAddFlowFee"
        type="primary"
        icon="el-icon-plus
"
        size="small"
        >新增</el-button
      >
    </div>
    <div class="list-area">
      <el-table v-loading="loading" height="100%" :data="feeData.list" stripe style="width: 100%; min-width: 1200px" :header-cell-style="{ background: '#eef1f6' }">
        <el-table-column align="center" label="上课模式">
          <template slot-scope="scope">
            <span>{{ scope.row.classMode == 1 ? "一对一" : "一对多" }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="视频模式">
          <template slot-scope="scope">
            <span v-show="scope.row.classMode == 1">{{
              scope.row.videoMode == 1 ? "普通模式" : "高级模式"
            }}</span>
            <span v-show="scope.row.classMode == 2">--</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="费用" width="260px">
          <template slot-scope="scope">
            <span v-show="scope.row.classMode == 1">
              <span
                >流量费用:{{
                  scope.row.flowFee ? scope.row.flowFee : 0
                }}元</span
              >
              <span class="mL10"
                >录制费用:{{
                  scope.row.playBackFee ? scope.row.playBackFee : 0
                }}元</span
              >
            </span>
            <el-button type="text" v-show="scope.row.classMode == 2 && authorities.includes('BACK:FLOW_FEE_DETAIL')" @click="handleSeeOneToMoreFeeDetail(scope.row)">查看</el-button>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="createTimeString"
          label="创建时间"
        >
        </el-table-column>
        <el-table-column align="center" label="状态">
          <template slot-scope="scope">
            <span v-show="scope.row.status == 1">启用</span>
            <span v-show="scope.row.status == 0">禁用</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="160px">
          <template slot-scope="scope">
            <el-link 
              v-show="authorities.includes('BACK:FLOW_FEE_UPDATE')"
              type="primary"
              :underline="false"
              @click="handleEdit(scope.row)"
              >修改</el-link
            >
            <el-link
              v-show="scope.row.status == 0 && authorities.includes('BACK:SET_FLOW_FEE')"
              class="mL20"
              type="primary"
              :underline="false"
              @click="handleDisableOrEnable(1, scope.row)"
              >启用</el-link
            >
            <el-link
              type="primary"
              v-show="scope.row.status == 1 && authorities.includes('BACK:SET_FLOW_FEE')"
              class="mL20"
              :underline="false"
              @click="handleDisableOrEnable(0, scope.row)"
              >禁用</el-link
            >
            <el-link
              v-show="authorities.includes('BACK:FLOW_FEE_DELETE')"
              type="primary"
              class="mL20"
              :underline="false"
              @click="handleDel(scope.row)"
              >删除</el-link
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="bottom-area">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="requestParams.pageNum"
        :page-size="requestParams.pageSize"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="feeData.total"
      >
      </el-pagination>
    </div>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogSaveData"
      width="560px"
    >
      <div>
        <el-form
          ref="saveData"
          size="small"
          :model="saveData"
          :rules="saveDataRules"
          label-width="100px"
        >
          <el-form-item label="上课模式" prop="classMode">
            <el-radio-group v-model="saveData.classMode">
              <el-radio :label="1">一对一</el-radio>
              <el-radio :label="2">一对多</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            v-show="saveData.classMode == 1"
            label="视频模式"
            prop="videoMode"
          >
            <el-radio-group v-model="saveData.videoMode">
              <el-radio :label="1">普通模式</el-radio>
              <el-radio :label="2">高级模式</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            v-show="saveData.classMode == 1"
            label="流量费用"
            prop="flowFee"
          >
            <el-row>
              <el-col :span="16"
                ><el-input
                  v-model="saveData.flowFee"
                  @input="handleLimitNum('flowFee', $event)"
                  placeholder="请输入费用"
                ></el-input
              ></el-col>
              <el-col :span="8"><span class="mL10">元/小时</span></el-col>
            </el-row>
          </el-form-item>
          <el-form-item
            v-show="saveData.classMode == 1"
            label="回放费用"
            prop="playBackFee"
          >
            <el-row>
              <el-col :span="16">
                <el-input
                  v-model="saveData.playBackFee"
                  @input="handleLimitNum('playBackFee', $event)"
                  placeholder="请输入费用"
                ></el-input>
              </el-col>
              <el-col :span="8">
                <span class="mL10">元/小时</span>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item
            v-show="saveData.classMode == 2"
            label="流量费用"
            prop="tflowFeeMoreList"
          >
            <el-row
              class="mB10"
              v-for="(item, index) in saveData.tflowFeeMoreList"
              :key="index"
            >
              <el-col :span="4">{{ item.name }}</el-col>
              <el-col :span="12">
                <el-input
                  v-model="item.flowFeeMore"
                  @input="
                    handleOneToMoreLimitNum(
                      'tflowFeeMoreList',
                      'flowFeeMore',
                      index,
                      $event
                    )
                  "
                  placeholder="请输入费用"
                ></el-input>
              </el-col>
              <el-col :span="8">
                <span class="mL10">元/小时</span>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item
            v-show="saveData.classMode == 2"
            label="回放费用"
            prop="tplaybackFeeMoreList"
          >
            <el-row
              class="mB10"
              v-for="(item, index) in saveData.tplaybackFeeMoreList"
              :key="index"
            >
              <el-col :span="4">{{ item.name }}</el-col>
              <el-col :span="12">
                <el-input
                  v-model="item.playBackFeeMore"
                  @input="
                    handleOneToMoreLimitNum(
                      'tplaybackFeeMoreList',
                      'playBackFeeMore',
                      index,
                      $event
                    )
                  "
                  placeholder="请输入费用"
                ></el-input>
              </el-col>
              <el-col :span="8">
                <span class="mL10">元/小时</span>
              </el-col>
            </el-row>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogSaveData = false"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="handleConfirmData"
          >保 存</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="一对多费用"
      :visible.sync="dialogOneToMoreDetail.show"
      width="500px"
    >
      <div class="dialog-content-detail">
        <el-row>
          <el-col :span="4">
            <div class="label">流量费用：</div>
          </el-col>
          <el-col :span="20">
            <div class="value" v-for="(item, index) in dialogOneToMoreDetail.tflowFeeMoreList" :key="index">
              <span v-if="index == 0">小班课(1-6人)</span>
              <span v-if="index == 1">中班课(1-10人)</span>
              <span v-if="index == 2">大班课(1-20人)</span>
              <span>{{item.flowFeeMore}}金币</span>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <div class="label">回放费用：</div>
          </el-col>
          <el-col :span="20">
            <div class="value" v-for="(item, index) in dialogOneToMoreDetail.tplayBackFeeMoreVO" :key="index">
              <span v-if="index == 0">小班课(1-6人)</span>
              <span v-if="index == 1">中班课(1-10人)</span>
              <span v-if="index == 2">大班课(1-20人)</span>
              <span>{{item.playBackFeeMore}}金币</span>
            </div>
          </el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="dialogOneToMoreDetail.show = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  flowFeeSave,
  getFlowList,
  setFlowFee,
  flowFeeUpdate,
  flowFeeDel,
} from "@/api/flowFee";
import { mapState } from 'vuex';
export default {
  data() {
    return {
      loading: false,
      dialogSaveData: false,
      dialogTitle: "新增",
      dialogOneToMoreDetail: {
        show: false,
        tflowFeeMoreList: [],
        tplayBackFeeMoreVO: [],
      },
      editId: "",
      saveData: {
        classMode: 1, // 1 一对一 2 一对多
        videoMode: 1, // 1 普通模式 2 高级模式
        flowFee: "",
        playBackFee: "",
        tflowFeeMoreList: [
          {
            name: "1-6人",
            flowStartPeople: 1,
            flowEndPeople: 6,
            flowFeeMore: "",
          },
          {
            name: "1-10人",
            flowStartPeople: 1,
            flowEndPeople: 10,
            flowFeeMore: "",
          },
          {
            name: "1-20人",
            flowStartPeople: 1,
            flowEndPeople: 10,
            flowFeeMore: "",
          },
        ],
        tplaybackFeeMoreList: [
          {
            name: "1-6人",
            playBackEndPeople: 6,
            playBackStartPeople: 1,
            playBackFeeMore: "",
          },
          {
            name: "1-10人",
            playBackEndPeople: 10,
            playBackStartPeople: 1,
            playBackFeeMore: "",
          },
          {
            name: "1-20人",
            playBackEndPeople: 20,
            playBackStartPeople: 1,
            playBackFeeMore: "",
          },
        ],
      },
      requestParams: {
        pageNum: 1,
        pageSize: 10,
      },
      feeData: {
        list: [],
        total: 0,
      },
      saveDataRules: {},
    };
  },
  components: {},
  computed: {
    ...mapState(['authorities']),
  },
  watch: {
    "saveData.classMode"() {
      this.initSaveDataRules();
    },
  },
  mounted() {
    this.initSaveDataRules();
    this.getFlowList();
  },
  methods: {
    /* 查看一对多费用详情 */
    handleSeeOneToMoreFeeDetail(row){
      const { tflowFeeMoreList, tplayBackFeeMoreVO } = row
      this.dialogOneToMoreDetail = {
        show: true,
        tflowFeeMoreList,
        tplayBackFeeMoreVO,
      }
    },
    /* 初始化保存数据规则 */
    initSaveDataRules() {
      if (this.saveData.classMode == 1) {
        this.saveDataRules = {
          classMode: [
            { required: true, message: "上课模式不能为空", trigger: "change" },
          ],
          videoMode: [
            { required: true, message: "视频模式不能为空", trigger: "change" },
          ],
          flowFee: [
            { required: true, message: "流量费用不能为空", trigger: "change" },
          ],
          playBackFee: [
            { required: true, message: "回放费用不能为空", trigger: "change" },
          ],
        };
      } else if (this.saveData.classMode == 2) {
        this.saveDataRules = {
          classMode: [
            { required: true, message: "上课模式不能为空", trigger: "change" },
          ],
          videoMode: [
            { required: true, message: "视频模式不能为空", trigger: "change" },
          ],
          tflowFeeMoreList: [
            { required: true, message: "流量费用不能为空", trigger: "change" },
            {
              trigger: "change",
              validator: (rule, value, callback) => {
                for (let i = 0, len = value.length; i < len; i++) {
                  if (!value[i].flowFeeMore) {
                    return callback(
                      new Error("输入框内容都为必填！且不能为空！")
                    );
                  }
                }
                return callback();
              },
            },
          ],
          tplaybackFeeMoreList: [
            { required: true, message: "回放费用不能为空", trigger: "change" },
            {
              trigger: "change",
              validator: (rule, value, callback) => {
                console.log("value: ", value);
                for (let i = 0, len = value.length; i < len; i++) {
                  if (!value[i].playBackFeeMore) {
                    return callback(
                      new Error("输入框内容都为必填！且不能为空！")
                    );
                  }
                }
                return callback();
              },
            },
          ],
        };
      }
      this.$nextTick(() => {
        if(this.$refs['saveData']){
          this.$refs['saveData'].clearValidate()
        }
      })
    },
    /* 获取流量设置列表 */
    async getFlowList() {
      if(!this.authorities.includes('BACK:FLOW_FEE_PAGE_LIST')){
        return
      }
      try {
        this.loading = true
        const { total, records, pages, current } = await getFlowList(this.requestParams);
        this.loading = false
        this.feeData = {
          total,
          list: records,
        };
        if(current > pages && pages) {
          this.requestParams.pageNum = pages 
          this.getFlowList()
        }
      } catch (err) {
        this.loading = false
        let msg = err.msg ? err.msg : "获取流量费用列表发生异常！";
        console.error("getFlowList: ", err);
        this.msgError(msg);
      }
    },
    /* 页码数量改变 */
    handleSizeChange(pageSize) {
      this.requestParams.pageSize = pageSize;
      this.getFlowList();
    },
    /* 页码改变 */
    handleCurrentChange(pageNum) {
      this.requestParams.pageNum = pageNum;
      this.getFlowList();
    },
    /* 处理新增 */
    handleAddFlowFee() {
      this.editId = '';
      this.dialogTitle = "新增";
      this.dialogSaveData = true;
      this.$nextTick(() => {
        if(this.$refs['saveData']){
          this.$refs['saveData'].resetFields()
        }
      })
    },
    /* 处理编辑 */
    handleEdit(row) {
      const {
        classMode,
        flowFee,
        id,
        playBackFee,
        tflowFeeMoreList,
        tplayBackFeeMoreVO,
        videoMode,
      } = row;
      this.editId = id;
      this.dialogTitle = "修改";
      this.saveData = {
        classMode,
        flowFee: flowFee ? flowFee : "",
        id,
        playBackFee: playBackFee ? playBackFee : "",
        tflowFeeMoreList: tflowFeeMoreList
          ? tflowFeeMoreList
          : [
              {
                name: "1-6人",
                flowStartPeople: 1,
                flowEndPeople: 6,
                flowFeeMore: "",
              },
              {
                name: "1-10人",
                flowStartPeople: 1,
                flowEndPeople: 10,
                flowFeeMore: "",
              },
              {
                name: "1-20人",
                flowStartPeople: 1,
                flowEndPeople: 10,
                flowFeeMore: "",
              },
            ],
        tplaybackFeeMoreList: tplayBackFeeMoreVO
          ? tplayBackFeeMoreVO
          : [
              {
                name: "1-6人",
                playBackEndPeople: 6,
                playBackStartPeople: 1,
                playBackFeeMore: "",
              },
              {
                name: "1-10人",
                playBackEndPeople: 10,
                playBackStartPeople: 1,
                playBackFeeMore: "",
              },
              {
                name: "1-20人",
                playBackEndPeople: 20,
                playBackStartPeople: 1,
                playBackFeeMore: "",
              },
            ],
        videoMode,
      };
      this.dialogSaveData = true;
    },
    /* 处理启用禁用 */
    async handleDisableOrEnable(status, row) {
      try {
        const data = await setFlowFee({
          id: row.id,
          status,
        });
        if (!data) {
          return this.msgError(
            `${status == 1 ? "启用" : "禁用"}操作失败！`
          );
        }
        this.msgSuccess(`${status == 1 ? "启用" : "禁用"}成功！`);
        this.getFlowList();
      } catch (err) {
        let msg = err.msg
          ? err.msg
          : `${status == 1 ? "启用" : "禁用"}发生异常！`;
        console.error("setFlowFee: ", err);
        this.msgError(msg);
      }
    },
    /* 处理删除 */
    handleDel(row) {
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        try {
          const data = await flowFeeDel(row.id);
          if (!data) {
            return this.msgError("删除失败");
          }
          this.getFlowList();
          this.msgSuccess("操作成功！");
        } catch (err) {
          let msg = err.msg ? err.msg : "删除发生异常！";
          console.error("flowFeeDel: ", err);
          this.msgError(msg);
        }
      });
    },
    /* 处理保存数据 */
    async handleConfirmData() {
      this.$refs["saveData"].validate(async (valid) => {
        if (valid) {
          const {
            classMode,
            videoMode,
            flowFee,
            playBackFee,
            tflowFeeMoreList,
            tplaybackFeeMoreList,
          } = this.saveData;
          let data = {};
          if (classMode == 1) {
            data = {
              classMode,
              videoMode,
              flowFee,
              playBackFee,
              tflowFeeMoreList: [],
              tplaybackFeeMoreList: [],
            };
          } else if (classMode == 2) {
            data = {
              classMode,
              videoMode: 1,  // 一对多视频类型默认都为普通模式
              flowFee: "",
              playBackFee: "",
              tflowFeeMoreList,
              tplaybackFeeMoreList,
            };
          }
          if(this._addOrEditFlag){
            return
          }
          this._addOrEditFlag = true
          try {
            if(this.dialogTitle == '新增'){
              await flowFeeSave(data);
            }else if(this.dialogTitle == '修改'){
              await flowFeeUpdate({ ...data, id: this.editId });
            }
            this._addOrEditFlag = false
            this.dialogSaveData = false;
            this.msgSuccess("保存成功！");
            this.getFlowList();
          } catch (err) {
            this._addOrEditFlag = false
            let msg = err.msg ? err.msg : "保存发生异常！";
            console.error("flowFeeSave: ", err);
            this.msgError(msg);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /* 限制只能输入数字(小数)问题 */
    handleLimitNum(type, value) {
      value =
        value
          .replace(/[^\d^\.]+/g, "")
          .replace(/^0+(\d)/, "$1")
          .replace(/^\./, "0.")
          .match(/^\d*(\.?\d{0,2})/g)[0] || "";
      this.saveData[type] = value;
    },
    handleOneToMoreLimitNum(type, prop, index, value) {
      value =
        value
          .replace(/[^\d^\.]+/g, "")
          .replace(/^0+(\d)/, "$1")
          .replace(/^\./, "0.")
          .match(/^\d*(\.?\d{0,2})/g)[0] || "";
      this.saveData[type][index][prop] = value;
    },
  },
};
</script>
<style lang='scss' scoped>
.flow-fee-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  .btn-area {
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .list-area {
    flex: 1;
    overflow-x: auto;
  }
  .bottom-area {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .dialog-content-detail{
    .label{
      line-height: 26px;
      font-weight: bold;
    }
    .value{
      line-height: 26px;
    }
  }
}
</style>